import React, { useEffect, useState, useContext } from 'react';
import axios from '../api/axios';
import { AuthContext } from '../context/AuthContext';
import QuoteModal from '../components/QuoteModal';

const QuoteListPage = () => {
    const { user } = useContext(AuthContext); // Get logged-in user details
    const [quotes, setQuotes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState('date'); // Default sort by date
    const [filterLocation, setFilterLocation] = useState('');
    const [filterUser, setFilterUser] = useState('');
    const [filterQuotedBy, setFilterQuotedBy] = useState(''); // New filter for quotedBy
    const [locations, setLocations] = useState([]); // Store unique locations
    const [users, setUsers] = useState([]); // Store unique users
    const [quotedByUsers, setQuotedByUsers] = useState([]); // Store unique quotedBy users
    const [filteredQuotes, setFilteredQuotes] = useState([]);
    const [modalOpen, setModalOpen] = useState(false); // Control modal visibility
    const [selectedQuote, setSelectedQuote] = useState(null); // Quote being edited

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const response = await axios.get('/quotes');
                const sortedQuotes = response.data.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date descending
                setQuotes(sortedQuotes);
                setFilteredQuotes(sortedQuotes);

                // Extract unique locations
                const uniqueLocations = [...new Set(sortedQuotes.map((quote) => quote.location))];
                setLocations(uniqueLocations);

                // Extract unique creators
                const uniqueUsers = [
                    ...new Map(
                        sortedQuotes.map((quote) => [
                            quote.creator._id,
                            `${quote.creator.firstName} ${quote.creator.lastName}`,
                        ])
                    ),
                ];
                setUsers(uniqueUsers);

                // Extract unique quotedBy users
                const uniqueQuotedBy = [
                    ...new Map(
                        sortedQuotes.map((quote) => [
                            quote.quotedBy._id,
                            `${quote.quotedBy.firstName} ${quote.quotedBy.lastName}`,
                        ])
                    ),
                ];
                setQuotedByUsers(uniqueQuotedBy);
            } catch (err) {
                console.error('Failed to fetch quotes:', err);
            }
        };

        fetchQuotes();
    }, []);

    // Filter, search, and sort quotes
    useEffect(() => {
        let filtered = [...quotes];

        // Search by text, location, or user
        if (searchTerm) {
            filtered = filtered.filter(
                (quote) =>
                    quote.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    quote.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    `${quote.creator.firstName} ${quote.creator.lastName}`
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
            );
        }

        // Filter by location
        if (filterLocation) {
            filtered = filtered.filter((quote) => quote.location === filterLocation);
        }

        // Filter by user
        if (filterUser) {
            filtered = filtered.filter(
                (quote) =>
                    `${quote.creator.firstName} ${quote.creator.lastName}` === filterUser
            );
        }

        // Filter by quotedBy
        if (filterQuotedBy) {
            filtered = filtered.filter(
                (quote) =>
                    `${quote.quotedBy.firstName} ${quote.quotedBy.lastName}` === filterQuotedBy
            );
        }

        // Sort by selected field
        if (sortField) {
            filtered.sort((a, b) => {
                if (sortField === 'date') {
                    return new Date(b.date) - new Date(a.date); // Newest first
                }
                if (sortField === 'location') {
                    return a.location.localeCompare(b.location);
                }
                if (sortField === 'user') {
                    const userA = `${a.creator.firstName} ${a.creator.lastName}`.toLowerCase();
                    const userB = `${b.creator.firstName} ${b.creator.lastName}`.toLowerCase();
                    return userA.localeCompare(userB);
                }
                if (sortField === 'quotedBy') {
                    const userA = `${a.quotedBy.firstName} ${a.quotedBy.lastName}`.toLowerCase();
                    const userB = `${b.quotedBy.firstName} ${b.quotedBy.lastName}`.toLowerCase();
                    return userA.localeCompare(userB);
                }
                return 0;
            });
        }

        setFilteredQuotes(filtered);
    }, [searchTerm, sortField, filterLocation, filterUser, filterQuotedBy, quotes]);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/quotes/${id}`, {
                headers: { Authorization: `Bearer ${user.token}` }, // Include user token
            });
            setQuotes(quotes.filter((quote) => quote._id !== id));
        } catch (err) {
            console.error('Failed to delete quote:', err);
        }
    };

    const handleModalSave = (savedQuote) => {
        setModalOpen(false); // Close the modal

        if (selectedQuote) {
            // Update the quote in the list
            setQuotes((prev) =>
                prev.map((quote) =>
                    quote._id === savedQuote._id ? savedQuote : quote
                )
            );
        } else {
            // Add new quote to the top of the list
            setQuotes((prev) => [savedQuote, ...prev]);
        }
    };

    const openCreateModal = () => {
        setSelectedQuote(null); // Ensure we're creating a new quote
        setModalOpen(true);
    };

    const openEditModal = (quote) => {
        setSelectedQuote(quote); // Load quote data into modal
        setModalOpen(true);
    };

    return (
        <div className="min-h-screen p-6">
            <h1 className="text-2xl font-bold mb-6">Monumentjes</h1>

            {/* Quote Modal */}
            <QuoteModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onSave={handleModalSave}
                initialData={selectedQuote}
                user={user}
            />

            {/* Search, Filter, and Sort */}
            <div className="mb-4 flex flex-wrap gap-4">
                <input
                    type="text"
                    placeholder="Zoek op text, locatie, of gebruiker..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border rounded"
                />
                <select
                    value={sortField}
                    onChange={(e) => setSortField(e.target.value)}
                    className="p-2 border rounded"
                >
                    <option value="">Sorteer op</option>
                    <option value="date">Datum</option>
                    <option value="location">Locatie</option>
                    <option value="user">Gebruiker</option>
                    <option value="quotedBy">Geciteerd door</option>
                </select>
                <select
                    value={filterLocation}
                    onChange={(e) => setFilterLocation(e.target.value)}
                    className="p-2 border rounded"
                >
                    <option value="">Filter op Locatie</option>
                    {locations.map((location) => (
                        <option key={location} value={location}>
                            {location}
                        </option>
                    ))}
                </select>
                <select
                    value={filterUser}
                    onChange={(e) => setFilterUser(e.target.value)}
                    className="p-2 border rounded"
                >
                    <option value="">Filter op Gebruiker</option>
                    {users.map(([id, name]) => (
                        <option key={id} value={name}>
                            {name}
                        </option>
                    ))}
                </select>
                <select
                    value={filterQuotedBy}
                    onChange={(e) => setFilterQuotedBy(e.target.value)}
                    className="p-2 border rounded"
                >
                    <option value="">Filter op Geciteerd door</option>
                    {quotedByUsers.map(([id, name]) => (
                        <option key={id} value={name}>
                            {name}
                        </option>
                    ))}
                </select>
                {user && (
                    <button
                        onClick={openCreateModal}
                        className="bg-green-500 text-white p-2 rounded"
                    >
                        Nieuw Monumentje
                    </button>
                )}
            </div>

            {/* Quote List */}
            <table className="w-full border-collapse border border-gray-300">
                <thead>
                <tr className="bg-gray-100">
                    <th className="border p-2">Monumentje</th>
                    <th className="border p-2">Locatie</th>
                    <th className="border p-2">Geciteerd door</th>
                    <th className="border p-2">Ingestuurd door</th>
                    <th className="border p-2">Datum</th>
                    {user && <th className="border p-2">Acties</th>}
                </tr>
                </thead>
                <tbody>
                {filteredQuotes.map((quote) => (
                    <tr key={quote._id} className="hover:bg-gray-50">
                        <td className="border p-2">{quote.text}</td>
                        <td className="border p-2">{quote.location}</td>
                        <td className="border p-2">
                            {quote.quotedBy.firstName} {quote.quotedBy.lastName}
                        </td>
                        <td className="border p-2">
                            {quote.creator.firstName} {quote.creator.lastName}
                        </td>
                        <td className="border p-2">
                            {new Date(quote.date).toLocaleString()}
                        </td>
                        {user && (
                            <td className="border p-2">
                                <button
                                    className="bg-yellow-500 text-white p-2 rounded mr-2"
                                    onClick={() => openEditModal(quote)}
                                >
                                    Wijzig
                                </button>
                            </td>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default QuoteListPage;
