import React, { useState } from 'react';
import axios from '../api/axios';

const ProfileEditModal = ({ isOpen, onClose, user, setUser }) => {
    const [formData, setFormData] = useState({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        dob: user.dob ? new Date(user.dob).toISOString().substring(0, 10) : '', // Format dob
        address: user.address,
        profilePicture: user.profilePicture || '',
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(null);
        setError(null);

        try {
            // Retrieve the token from localStorage
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const token = storedUser?.token;
            if (!token) {
                throw new Error('Token is missing or invalid.');
            }

            // Check if email was changed
            if (formData.email !== user.email) {
                const emailCheckResponse = await axios.post(
                    '/users/check-email',
                    { email: formData.email },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                if (!emailCheckResponse.data.available) {
                    setError('Email address is already in use.');
                    return;
                }
            }

            // Upload profile picture if selected
            let profilePictureUrl = formData.profilePicture;
            if (selectedFile) {
                const formDataForUpload = new FormData();
                formDataForUpload.append('file', selectedFile);

                try {
                    const uploadResponse = await axios.post(
                        '/users/upload-profile-picture',
                        formDataForUpload,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );
                    profilePictureUrl = uploadResponse.data.url; // Use the returned URL
                } catch (err) {
                    console.error('Failed to upload profile picture:', err);
                    setError('Failed to upload profile picture.');
                    return; // Exit if upload fails
                }
            }

            // Update user information
            const updatedUser = await axios.put(
                `/users/${user._id}`,
                { ...formData, profilePicture: profilePictureUrl },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setMessage('Profile updated successfully.');
            setUser(updatedUser.data); // Update user in parent component
            onClose(); // Close modal
        } catch (err) {
            console.error('Error updating profile:', err);
            setError('Failed to update profile. Please try again.');
        }
    };

    if (!isOpen) return null; // Don’t render modal if not open

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">Edit Profile</h2>
                {message && <p className="text-green-500 mb-4">{message}</p>}
                {error && <p className="text-red-500 mb-4">{error}</p>}

                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Voornaam"
                        className="block w-full p-2 mb-4 border"
                    />
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Achternaam"
                        className="block w-full p-2 mb-4 border"
                    />
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email"
                        className="block w-full p-2 mb-4 border"
                    />
                    <input
                        type="date"
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        className="block w-full p-2 mb-4 border"
                    />
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        placeholder="Adres"
                        className="block w-full p-2 mb-4 border"
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="block w-full p-2 mb-4"
                    />
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                        >
                            Annuleren
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Opslaan
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProfileEditModal;
