import React, { useState, useContext } from 'react'; // Include useContext
import axios from '../api/axios';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext

const UserModal = ({ data, onClose, onSave }) => {
    const { user } = useContext(AuthContext); // Access the logged-in user
    const [formData, setFormData] = useState(
        data || { firstName: '', lastName: '', email: '', dob: '', address: '', role: 'user', password: '' }
    );
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateEmail = async () => {
        // Skip validation if the email hasn't changed
        if (data && formData.email === data.email) {
            return true; // Treat as valid since it's unchanged
        }

        try {
            const response = await axios.post(
                '/users/check-email',
                {
                    email: formData.email,
                    userId: data ? data._id : null, // Exclude the current user for edit cases
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`, // Include the user's token
                    },
                }
            );
            return response.data.valid; // Expect backend to return { valid: true/false }
        } catch (err) {
            console.error('Error validating email:', err);
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prevent logged-in admin from changing their own role
        if (data && data._id === user.id && formData.role !== 'admin') {
            setError("You can't change your own role");
            return;
        }

        // Basic validation
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.dob || !formData.address) {
            setError('All fields are required');
            return;
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
            setError('Invalid email format');
            return;
        }

        // Check for unique email
        const isEmailValid = await validateEmail();
        if (!isEmailValid) {
            setError('Email already in use');
            return;
        }

        setError('');
        onSave(formData);
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-md">
                <h2 className="text-xl font-bold mb-4">{data ? 'Edit User' : 'Create User'}</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-1">Voornaam</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Achternaam</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Geboortedatum</label>
                        <input
                            type="date"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Adres</label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Rol</label>
                        <select
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        >
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                    {!data && (
                        <div className="mb-4">
                            <label className="block mb-1">Wachtwoord</label>
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                    )}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                        >
                            Annuleren
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Opslaan
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserModal;
