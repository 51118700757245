import React, { useState, useEffect } from 'react';
import axios from '../api/axios';
import ProfileEditModal from '../components/ProfileEditModal'; // Modal for editing profile
import ChangePasswordModal from '../components/ChangePasswordModal'; // Import Change Password Modal


const ProfilePage = () => {
    const [user, setUser] = useState(null); // Holds user data
    const [loading, setLoading] = useState(true); // Loading state
    const [modalOpen, setEditModalOpen] = useState(false); // Modal visibility
    const [passwordModalOpen, setPasswordModalOpen] = useState(false); // Change Password Modal visibility


    useEffect(() => {
        const fetchUser = async () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem('user')); // Retrieve the user object
                if (!storedUser || !storedUser.token) {
                    throw new Error('Token not found in localStorage');
                }

                const response = await axios.get('/users/me', {
                    headers: { Authorization: `Bearer ${storedUser.token}` }, // Pass token in header
                });

                setUser(response.data); // Set the user data
            } catch (err) {
                console.error('Failed to fetch user:', err);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchUser(); // Fetch user data on mount
    }, []);


    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p>Loading...</p>
            </div>
        );
    }

    if (!user) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p>Failed to load profile data.</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen p-6">
            <h1 className="text-2xl font-bold mb-6">Profiel</h1>

            {/* Profile Information */}
            <div className="bg-gray-100 p-4 rounded shadow-md max-w-lg mx-auto">
                {/* Profile Picture */}
                <div className="flex justify-center mb-4">
                    <img
                        src={user.profilePicture || '/default-profile.png'}
                        alt="Profile"
                        className="w-32 h-32 rounded-full border"
                    />
                </div>

                {/* User Information */}
                <div className="space-y-2">
                    <p><strong>Voornaam:</strong> {user.firstName}</p>
                    <p><strong>Achternaam:</strong> {user.lastName}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                    <p>
                        <strong>Geboortedatum:</strong>{' '}
                        {user.dob ? new Date(user.dob).toLocaleDateString() : 'N/A'}
                    </p>
                    <p><strong>Adres:</strong> {user.address}</p>
                </div>

                {/* Edit Button */}
                <div className="text-center mt-4 space-x-4">
                    <button
                        onClick={() => setEditModalOpen(true)}
                        className="bg-blue-500 text-white py-2 px-4 rounded"
                    >
                        Wijzig gegevens
                    </button>
                    <button
                        onClick={() => setPasswordModalOpen(true)}
                        className="bg-red-500 text-white py-2 px-4 rounded"
                    >
                        Wijzig wachtwoord
                    </button>
                </div>
            </div>

            {/* Edit Modal */}
            {modalOpen && (
                <ProfileEditModal
                    isOpen={modalOpen}
                    onClose={() => setEditModalOpen(false)}
                    user={user}
                    setUser={setUser} // Pass function to update user data
                />
            )}

            {passwordModalOpen && (
                <ChangePasswordModal
                    isOpen={passwordModalOpen}
                    onClose={() => setPasswordModalOpen(false)}
                />
            )}
        </div>
    );
};

export default ProfilePage;
