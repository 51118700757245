import React, { useState, useContext } from 'react';
import axios from '../api/axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/auth/login', formData);
      const { token } = response.data;

      // Decode token to get user info
      const payload = JSON.parse(atob(token.split('.')[1]));
      const user = { id: payload.id, role: payload.role, token };

      const isExpired = payload.exp * 1000 < Date.now(); // Convert exp to milliseconds
      if (isExpired) {
        throw new Error('Token has expired');
      }

      // Save user info in context and localStorage
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));

      // Redirect to the homepage
      navigate('/');
    } catch (err) {
      setError('emailadres of wachtwoord incorrect');
    }
  };

  return (
      <div className="min-h-screen flex items-center justify-center">
        <form className="bg-white shadow-md p-6 rounded" onSubmit={handleSubmit}>
          <h1 className="text-xl font-bold mb-4">Login</h1>
          {error && <p className="text-red-500">{error}</p>}
          <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              className="block w-full mb-4 p-2 border"
          />
          <input
              type="password"
              name="password"
              placeholder="Wachtwoord"
              onChange={handleChange}
              className="block w-full mb-4 p-2 border"
          />
          <button className="bg-blue-500 text-white py-2 px-4 rounded">
            Login
          </button>
        </form>
      </div>
  );
};

export default LoginPage;
