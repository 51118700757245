import React, { useState, useEffect, useContext } from 'react';
import axios from '../api/axios';
import { AuthContext } from '../context/AuthContext';
import UserModal from '../components/UserModal';

const AdminPage = () => {
    const { user } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]); // Users after search
    const [searchTerm, setSearchTerm] = useState(''); // Search input state
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('/users', {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                setUsers(response.data);
                setFilteredUsers(response.data); // Initialize filtered users
            } catch (err) {
                console.error('Failed to fetch users:', err);
            }
        };

        fetchUsers();
    }, [user.token]);

    // Filter users based on the search term
    useEffect(() => {
        if (!searchTerm) {
            setFilteredUsers(users); // Reset if search is cleared
        } else {
            const lowercasedTerm = searchTerm.toLowerCase();
            const filtered = users.filter(
                (u) =>
                    u.firstName.toLowerCase().includes(lowercasedTerm) ||
                    u.lastName.toLowerCase().includes(lowercasedTerm) ||
                    u.email.toLowerCase().includes(lowercasedTerm) ||
                    u.role.toLowerCase().includes(lowercasedTerm)
            );
            setFilteredUsers(filtered);
        }
    }, [searchTerm, users]);

    const handleDelete = async (userId) => {
        if (userId === user.id) {
            alert("You can't delete yourself!");
            return;
        }

        const confirm = window.confirm('Are you sure you want to delete this user?');
        if (!confirm) return;

        try {
            await axios.delete(`/users/${userId}`, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setUsers(users.filter((u) => u._id !== userId));
        } catch (err) {
            console.error('Failed to delete user:', err);
        }
    };

    const openModal = (userData = null) => {
        setModalData(userData);
        setShowModal(true);
    };

    const closeModal = () => {
        setModalData(null);
        setShowModal(false);
    };

    const handleSave = async (data) => {
        try {
            if (modalData) {
                // Edit user
                await axios.put(`/users/${modalData._id}`, data, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
            } else {
                // Create new user
                await axios.post('/users', data, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
            }

            // Refresh user list
            const response = await axios.get('/users', {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setUsers(response.data);
            closeModal();
        } catch (err) {
            console.error('Failed to save user:', err);
        }
    };

    return (
        <div className="min-h-screen p-6">
            <h1 className="text-2xl font-bold mb-6">Admin Page</h1>

            {/* Search Bar */}
            <div className="flex justify-between items-center mb-4">
                <input
                    type="text"
                    placeholder="Search users by any field..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border rounded w-full max-w-md"
                />
                <button
                    onClick={() => openModal()}
                    className="bg-green-500 text-white px-4 py-2 rounded ml-4"
                >
                    Create User
                </button>
            </div>

            {/* User Table */}
            <table className="w-full border-collapse border border-gray-300">
                <thead>
                <tr className="bg-gray-100">
                    <th className="border p-2">First Name</th>
                    <th className="border p-2">Last Name</th>
                    <th className="border p-2">Email</th>
                    <th className="border p-2">Date of Birth</th>
                    <th className="border p-2">Address</th>
                    <th className="border p-2">Role</th>
                    <th className="border p-2">Actions</th>
                </tr>
                </thead>
                <tbody>
                {filteredUsers.map((u) => (
                    <tr key={u._id} className="hover:bg-gray-50">
                        <td className="border p-2">{u.firstName}</td>
                        <td className="border p-2">{u.lastName}</td>
                        <td className="border p-2">{u.email}</td>
                        <td className="border p-2">{new Date(u.dob).toLocaleDateString()}</td>
                        <td className="border p-2">{u.address}</td>
                        <td className="border p-2">{u.role}</td>
                        <td className="border p-2">
                            <button
                                onClick={() => openModal(u)}
                                className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDelete(u._id)}
                                className="bg-red-500 text-white px-2 py-1 rounded"
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {showModal && (
                <UserModal
                    data={modalData}
                    onClose={closeModal}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default AdminPage;
