import React, { useState, useEffect, useContext } from 'react';
import axios from '../api/axios';
import { AuthContext } from "../context/AuthContext";
import QuoteModal from '../components/QuoteModal';

const HomePage = () => {
    const [quote, setQuote] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // Manage modal visibility
    const { user } = useContext(AuthContext); // Get logged-in user details

    const fetchRandomQuote = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/quotes/random');
            setQuote(response.data);
        } catch (err) {
            console.error('Error fetching random quote:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRandomQuote(); // Fetch a quote when the component mounts
    }, []);

    const handleModalSave = (newQuote) => {
        setModalOpen(false); // Close the modal
    };

    const openCreateModal = () => {
        setModalOpen(true); // Open modal for new quote creation
    };

    return (
        <div className="min-h-screen flex flex-col justify-center items-center p-6">
            <div className="w-full max-w-md text-center">
                {loading ? (
                    <p className="text-gray-500">Laden...</p>
                ) : quote ? (
                    <>
                        <blockquote className="italic text-xl mb-4">"{quote.text}"</blockquote>
                        <p className="text-sm text-gray-600 mb-2">
                            - {quote.quotedBy.firstName} {quote.quotedBy.lastName}, {quote.location}
                        </p>
                        <p className="text-sm text-gray-600">
                            Ingestuurd door: {quote.creator.firstName} {quote.creator.lastName}
                        </p>
                    </>
                ) : (
                    <p className="text-gray-500">Geen monumentjes beschikbaar.</p>
                )}
                <div className="mb-4">
                    {/* Randomise Button */}
                    <button
                        onClick={fetchRandomQuote}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-full"
                    >
                        Randomise!
                    </button>
                </div>
                {user && (
                    <div>
                        {/* Nieuw Monumentje Button */}
                        <button
                            onClick={openCreateModal}
                            className="bg-green-500 text-white px-4 py-2 rounded w-full"
                        >
                            Nieuw Monumentje
                        </button>
                    </div>
                )}
            </div>

            {/* Quote Modal */}
            <QuoteModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onSave={handleModalSave}
                initialData={null}
                user={user}
            />
        </div>
    );
};

export default HomePage;
