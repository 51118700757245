import React, { useState, useEffect } from 'react';
import axios from '../api/axios';

const QuoteModal = ({ isOpen, onClose, onSave, initialData, user }) => {
    const [formData, setFormData] = useState({ location: '', text: '', quotedBy: '', date: '' });
    const [message, setMessage] = useState(null);
    const [users, setUsers] = useState([]); // List of users for the dropdown

    useEffect(() => {
        // Fetch users when the modal opens
        if (isOpen) {
            axios
                .get('/users', {
                    headers: { Authorization: `Bearer ${user.token}` },
                })
                .then((response) => setUsers(response.data))
                .catch((err) => console.error('Error fetching users:', err));
        }

        if (initialData) {
            setFormData(initialData); // Load data into form for editing
        } else {
            setFormData({ location: '', text: '', quotedBy: '', date: '' }); // Reset form for creating
        }
    }, [isOpen, initialData]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (initialData) {
                // Update quote
                const response = await axios.put(`/quotes/${initialData._id}`, formData, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                setMessage('Quote updated successfully');
                onSave(response.data);
            } else {
                // Create new quote
                const response = await axios.post('/quotes', formData, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                setMessage('Quote created successfully');
                onSave(response.data);
            }
        } catch (err) {
            setMessage('Error saving quote');
        }
    };

    if (!isOpen) return null; // Don't render if the modal is closed

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">
                    {initialData ? 'Wijzig Monumentje' : 'Nieuw Monumentje'}
                </h2>
                {message && <p className="text-green-500 mb-4">{message}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="location" className="block text-gray-700 font-medium mb-2">
                            Locatie
                        </label>
                        <input
                            id="location"
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            placeholder="Voer de locatie in"
                            className="block w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="text" className="block text-gray-700 font-medium mb-2">
                            Monumentje
                        </label>
                        <textarea
                            id="text"
                            name="text"
                            value={formData.text}
                            onChange={handleChange}
                            placeholder="Voer het monumentje in"
                            className="block w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="quotedBy" className="block text-gray-700 font-medium mb-2">
                            Geciteerd door
                        </label>
                        <select
                            id="quotedBy"
                            name="quotedBy"
                            value={formData.quotedBy}
                            onChange={handleChange}
                            className="block w-full p-2 border rounded"
                        >
                            <option value="">Selecteer een gebruiker</option>
                            {users.map((user) => (
                                <option key={user._id} value={user._id}>
                                    {user.firstName} {user.lastName}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Conditionally render the date input for Admins */}
                    {user.role === 'admin' && (
                        <div className="mb-4">
                            <label htmlFor="date" className="block text-gray-700 font-medium mb-2">
                                Datum
                            </label>
                            <input
                                id="date"
                                type="datetime-local"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                placeholder="Selecteer de datum"
                                className="block w-full p-2 border rounded"
                            />
                        </div>
                    )}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                        >
                            Annuleren
                        </button>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                            Opslaan
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default QuoteModal;
