import React, { useContext, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Navbar = () => {
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [menuOpen, setMenuOpen] = useState(false);

    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('user');
        navigate('/login');
    };

    const isActive = (path) => location.pathname === path;

    return (
        <nav className="bg-blue-500 text-white px-6 py-4">
            <div className="max-w-7xl mx-auto flex justify-between items-center">
                {/* Logo */}
                <Link to="/" className="text-2xl font-bold">
                    Vreetclub
                </Link>

                {/* Hamburger Menu */}
                <button
                    onClick={() => setMenuOpen(!menuOpen)}
                    className="sm:hidden text-white focus:outline-none text-2xl"
                >
                    ☰
                </button>

                {/* Links */}
                <div
                    className={`${
                        menuOpen ? 'block' : 'hidden'
                    } sm:flex sm:items-center sm:space-x-6`}
                >
                    {user && (
                        <>
                            <Link
                                to="/quotes"
                                className={`block sm:inline-block py-2 sm:py-0 hover:underline ${
                                    isActive('/quotes') ? 'font-bold underline' : ''
                                }`}
                            >
                                Monumentjes
                            </Link>
                            <Link
                                to="/profile"
                                className={`block sm:inline-block py-2 sm:py-0 hover:underline ${
                                    isActive('/profile') ? 'font-bold underline' : ''
                                }`}
                            >
                                Mijn Profiel
                            </Link>
                            {user?.role === 'admin' && (
                                <Link
                                    to="/admin"
                                    className={`block sm:inline-block py-2 sm:py-0 hover:underline ${
                                        isActive('/admin') ? 'font-bold underline' : ''
                                    }`}
                                >
                                    Admin
                                </Link>
                            )}
                        </>
                    )}
                </div>

                {/* Logout/Login */}
                <div className={`${
                    menuOpen ? 'block' : 'hidden'
                } sm:block`}>
                    {user ? (
                        <button
                            onClick={handleLogout}
                            className="block sm:inline-block bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                        >
                            Uitloggen
                        </button>
                    ) : (
                        <Link
                            to="/login"
                            className="block sm:inline-block bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                        >
                            Inloggen
                        </Link>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
